import React from "react";
import fast from "../../img/fast.png";
import responsive from "../../img/responsive.png";
import intuitive from "../../img/intuitive.png";
import dynamic from "../../img/dynamic.png";

const SkillsLeft = () => {
  return (
    <div className="skills-left row">
      <div className="detail col-lg-6 col-md-3">
        <img src={fast} alt=""></img>
        <h4>Fast</h4>
        <p>Fast load times and lag free interaction, my highest priority.</p>
      </div>
      <div className="detail col-lg-6 col-md-3">
        <img src={responsive} alt=""></img>
        <h4>Responsive</h4>
        <p>My layouts will work on any device, big or small.</p>
      </div>
      <div className="detail col-lg-6 col-md-3">
        <img src={intuitive} alt=""></img>
        <h4>Intuitive</h4>
        <p>Strong preference for easy to use, intuitive UX/UI.</p>
      </div>
      <div className="detail col-lg-6 col-md-3">
        <img src={dynamic} alt=""></img>
        <h4>Dynamic</h4>
        <p>
          Websites don't have to be static, I love making pages come to life.
        </p>
      </div>
    </div>
  );
};

export default SkillsLeft;
