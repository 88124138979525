import React, { useState } from "react";
import "./css/work.css";
import Carousel from "react-bootstrap/Carousel";
import { carouselItems } from "./shared/CarouselItems";
const Work = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="flexup">
      <div className="title">
        <h1 className="header">My Work</h1>
        <h3 className="some-projects">some projects</h3>
      </div>
      <Carousel interval={100000} activeIndex={index} onSelect={handleSelect}>
        {carouselItems.map((item, i) => (
          <Carousel.Item key={i}>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <img
                  className="d-block w-100"
                  src={item.image}
                  alt="First slide"
                />
              </div>
              <div className="col-lg-6  project-overview">
                <h2 className="overview">{item.title}</h2>
                <h5 style={{ color: "#2D88FF" }}>Overview</h5>
                <p style={{ maxWidth: "80%" }}>{item.overview}</p>
                <h5 style={{ color: "#2D88FF" }}>Project Skills</h5>
                {item.skills.map((skill, i) => (
                  <h6 key={i}>{skill}</h6>
                ))}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Work;
