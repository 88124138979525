import React from 'react';

const SkillsRight = () => {
	return (
		<div className="skills-right">
			<div className="bar">
				<div className="bar-fill">
					<span className="course">HTML5</span>
				</div>
				<span className="precent">
					<strong>90%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">CSS3</span>
				</div>
				<span className="precent">
					<strong>90%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">JavaScript</span>
				</div>
				<span className="precent">
					<strong>90%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">React</span>
				</div>
				<span className="precent">
					<strong>90%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">Redux</span>
				</div>
				<span className="precent">
					<strong>80%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">Sass</span>
				</div>
				<span className="precent">
					<strong>80%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">TypeScript</span>
				</div>
				<span className="precent">
					<strong>80%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">React Native</span>
				</div>
				<span className="precent">
					<strong>80%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">Node.js</span>
				</div>
				<span className="precent">
					<strong>70%</strong>
				</span>
			</div>
			<div className="bar">
				<div className="bar-fill">
					<span className="course">Ionic</span>
				</div>
				<span className="precent">
					<strong>70%</strong>
				</span>
			</div>
		</div>
	);
};

export default SkillsRight;
