import React from "react";
import hossam from "../../img/hossam.jpg";

const AboutRight = () => {
  return (
    <div className="about-right">
      <img src={hossam} alt=""></img>
    </div>
  );
};

export default AboutRight;
