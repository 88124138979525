import React, { useEffect } from 'react';
import './css/contact.css';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import MapCode from './MapCode.json';

const Contact = ({ google }) => {
	useEffect(() => {
		document.getElementById('newSpan').innerHTML = document
			.getElementById('newSpan')
			.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
	});

	const sendEmail = (e) => {
		const timer = setTimeout(() => {
			window.location.href = '/';
		}, 2000);
		return () => clearTimeout(timer);
	};
	return (
		<div className="contactPage">
			<div className="row">
				<div className="contact-form col-lg-6 col-md-12">
					<div className="contact-inputs">
						<h1 id="newSpan">Contact me</h1>
						<p>
							Have a question or want to work together? don’t hesitate to contact me using below
							form.
						</p>
						<form
							id="gform"
							method="POST"
							action="https://script.google.com/macros/s/AKfycbyc0trOSRTPZykGyUg0lXrXxdWjWNwuc-DQZZcp/exec"
						>
							<input
								autoComplete="off"
								className="name"
								name="name"
								type="text"
								placeholder="Name"
								required
							></input>
							<input
								autoComplete="off"
								className="email"
								name="email"
								type="email"
								placeholder="Email"
								required
							></input>
							<input
								autoComplete="off"
								className="subject"
								type="text"
								placeholder="Subject"
							></input>
							<textarea
								className="textarea"
								name="message"
								type="text"
								placeholder="Message"
							></textarea>
							<button onClick={sendEmail}>SEND</button>
						</form>
					</div>
				</div>

				<div className="map col-lg-6 col-md-12" style={{ height: '100%' }}>
					<Map
						google={google}
						zoom={7}
						containerStyle={{
							width: '100%',
							height: '100%',
							position: 'relative',
						}}
						initialCenter={{ lat: 35.1923177, lng: 33.3623828 }}
						streetViewControl={false}
						disableDefaultUI={true}
						styles={MapCode}
					>
						<div className="card">
							<p>Hossam Soliman, </p>
							<p>Nicosia, Cyprus</p>
							<p>(+357) 960 888 10</p>
							<p>
								<span style={{ color: '#2D88FF' }}>@</span>: hsoliman075@gmail.com
							</p>
						</div>

						<Marker position={{ lat: 35.1923177, lng: 33.3623828 }} />
					</Map>
				</div>
			</div>
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: 'AIzaSyDsNpUbIakdm64k-GWrjZLnXODm97CypF0',
})(Contact);
