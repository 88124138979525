import converter from "../img/converter.png";
import hub from "../img/hub.png";
import fleet from "../img/fleet.png";
import shop from "../img/shop.gif";
import floward from "../img/floward.png";
import location from "../img/location.png";

export const carouselItems = [
  {
    image: floward,
    title: "Floward",
    overview:
      "Floward main website in two languages English and Arabic, Floward is an online flowers and gifting ecommerce solution sourced from local and international brands with same-day delivery schedules.",
    skills: [
      "NextJs",
      "Context API",
      "TypeScript",
      "Tailwind",
      "ES6",
    ],
  },
  {
    image: hub,
    title: "Floward-Dashboard",
    overview:
      "Created from scratch the internal Floward Dashboard in React-Redux having many features such as Authentication, Authorization, Payments, Products, Orders, Drivers, Categories, company ticketing system, etc.",
    skills: ["ReactJs", "Redux","ES6", "CSS3", "HTML5", "Bootstrap"],
  },
  {
    image: fleet,
    title: "Fleet App",
    overview:
      "Created from scratch Floward Fleet App in dark/light modes for tracking drivers locations on map, orders and trips statuses, delivery ETA with LMFS, orders dispatch, orders manual sort, scanning order QR code, create drivers bulk actions, and optimizing trips for drivers by the nearby orders locations.",
    skills: [
      "ReactJs",
      "Redux",
      "Material UI",
      "ES6",
      "SCSS",
      "HTML5/CSS3",
    ],
  },
  {
    image: location,
    title: "Location Service",
    overview:
      "Created from scratch Floward Location Service app in Ionic-React in two languages designated for the customer to update order details such as address by dropping pin on map, delivery time, card details, recepient details, etc.",
    skills: [
      "Ionic-ReactJs",
      "Redux",
      "ES6",
      "SCSS",
      "HTML5/CSS3",
    ],
  },
  {
    image: converter,
    title: "Currency-Converter",
    overview:
      "This app is created using React and Redux, it enables the user to get free live and updated currency rates, tools, and analysis using APIs to get the required data, and the user can make an instant conversion between any pair of currencies. Planning to add more services such as money transfer, datafeed, and more!",
    skills: ["Javascript", "React, Redux", "ES6", "CSS3", "HTML5"],
  },
  {
    image: shop,
    title: "Online-Shop",
    overview:
      "A MERN stack application to enable the customer doing online shopping among a variety of products by picking his purchases in the cart and pay the sum by paypal. The customer can check all specificatios and prices of all products. And as online store, the user can either purchase a product or sell his own stuff on the store by uploading his product's photos, specifications and price to be shown for the other customers who can proceed the purchase.",
    skills: [
      "React, Redux, Redux thunk",
      "Javascript",
      "HTML5/CSS3",
      "Bootstrap",
      "NodeJS",
      "MongoDB",
    ],
  },
];
