import React from 'react';
import { Link } from 'react-router-dom';

const HomeLeft = () => {
	return (
		<div className="home-left">
			<h1>
				<span>Hi,</span>
				<br></br> <span>I</span>
				<span>'</span>
				<span>m</span> <span>H</span>
				<span>o</span>
				<span>s</span>
				<span>s</span>
				<span>a</span>
				<span>m</span>
				<span>,</span>
				<br></br>
				<span>w</span>
				<span>e</span>
				<span>b</span> <span>d</span>
				<span>e</span>
				<span>v</span>
				<span>e</span>
				<span>l</span>
				<span>o</span>
				<span>p</span>
				<span>e</span>
				<span>r</span>
				<span>.</span>
			</h1>
			<h2 id="ml2">Front End Engineer | ReactJS</h2>
			<Link to="/contact">
				<button>CONTACT ME</button>
			</Link>
		</div>
	);
};

export default HomeLeft;
