import React, { lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//components
import Home from "./Home";
import About from "./About";
import Skills from "./Skills";
import Work from "./Work";
import Contact from "./Contact";
import Loader from "./Loader";

const Navbar = lazy(() => import("./Navbar"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <div className="app">
          <Navbar />
          <div className="main-content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/skills" component={Skills} />
              <Route path="/portfolio" component={Work} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
