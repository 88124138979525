import React from 'react';

const AboutLeft = () => {
	return (
		<div className="about-left">
			<h1>
				<span>A</span>
				<span>b</span>
				<span>o</span>
				<span>u</span>
				<span>t</span>
				<span>&nbsp;</span>
				<span>m</span>
				<span>e</span>
			</h1>
			<p>
				I'm a web developer with 4+ years of experience building and maintaining responsive
				websites.<br></br> Proficient in HTML, CSS, JavaScript, plus modern libraries and
				frameworks.<br></br> Passionate about building fullstack products, developing and
				integrating APIs, and working with a variety of databases.
				<br></br> The Front-end use of HTML, CSS, ReactJS and Bootstrap to build a responsive web
				applications that can communicate with the Back-end.
				<br></br> I have serious passion for UI effects, animations and creating dynamic user
				experiences.<br></br> I have a bachelor's degree in Electronics and Communications
				Engineering.
			</p>
		</div>
	);
};

export default AboutLeft;
