import React from "react";
import PageWrapper from "./shared/PageWrapper";
import AboutLeft from "./pages/about/AboutLeft";
import AboutRight from "./pages/about/AboutRight";
import "./css/about.css";

const About = () => {
  return (
    <div className="about-page">
      <PageWrapper left={<AboutLeft />} right={<AboutRight />} about />
    </div>
  );
};

export default About;
