import React from "react";
import PageWrapper from "./shared/PageWrapper";

import "./css/skills.css";
import SkillsLeft from "./pages/skills/SkillsLeft";
import SkillsRight from "./pages/skills/SkillsRight";

const Skills = () => {
  return (
    <div className="skillsPage">
      <h1>Skills</h1>
      <PageWrapper left={<SkillsLeft />} right={<SkillsRight />} skills />
    </div>
  );
};

export default Skills;
