import React from 'react';
import node from '../../img/node.jpg';
import second from '../../img/second.png';
import css from '../../img/css.png';
import js from '../../img/new.png';
import react from '../../img/react.png';
import ts from '../../img/ts.png';

const HomeRight = () => {
	return (
		<div className="home-right">
			<img className="node" src={node} alt=""></img>
			<img className="css" src={css} alt=""></img>
			<img className="react" src={react} alt=""></img>
			<img className="mongo" src={ts} alt=""></img>
			<img className="html" src={second} alt=""></img>
			<img className="js" src={js} alt=""></img>
		</div>
	);
};

export default HomeRight;
