import React from "react";
import PageWrapper from "./shared/PageWrapper";
import HomeLeft from "./pages/home/HomeLeft";
import HomeRight from "./pages/home/HomeRight";
import "./css/home.css";

const Home = () => {
  return (
    <div className="homePage">
      <PageWrapper left={<HomeLeft />} right={<HomeRight />} home />
    </div>
  );
};

export default Home;
