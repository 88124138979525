import React from "react";

const PageWrapper = ({ home, left, right, about, skills, map }) => {
  return (
    <div
      className={`wrapper ${home ? "style-home" : ""} ${
        skills ? "skills-wrapper" : ""
      }`}
    >
      <div className={`${map ? "map-up" : "row"}`}>
        <div
          className={`col-lg-5  col-md-12 ${
            skills ? "left-skills" : "left-content"
          }`}
        >
          {left}
        </div>
        <div
          className={`col-lg-6 col-md-12 ${
            map ? "map-content" : "right-content"
          }`}
        >
          {right}
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
